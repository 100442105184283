import React, { useState } from 'react'
import Layout from '../components/Layout';
import Seo from '../components/seo';
import SubPageWrapper from '../components/SubPageWrapper';
import Inner from '../components/Inner';
import Title from '../components/Title';
import Box from '../components/Box';
import * as Styles from '../styles/pages/_member.module.scss';
import BackButton from '../components/back-button';
import { css } from '@emotion/react';
import Modal from 'react-modal';


import img_member_sunada from '../images/member/member_sunada.png';
import img_member_higashikawa from '../images/member/member_higashikawa.png';
import img_member_ogawa from '../images/member/member_ogawa.png';
import img_member_nakajima from '../images/member/member_nakajima.png';
import img_member_iwabuchi from '../images/member/member_iwabuchi.png';
import img_member_kato from '../images/member/member_kato.png';
import img_member_araki from '../images/member/member_araki.png';
import img_member_iemura from '../images/member/member_iemura.png';
import img_member_akou from '../images/member/member_akou.png';
import img_member_ozaki from '../images/member/member_ozaki.png';
import img_member_negishi from '../images/member/member_negishi.png';
import img_member_ito from '../images/member/member_ito.png';
import img_member_kazama from '../images/member/member_kazama.png';
import img_member_tomita from '../images/member/member_tomita.png';
import img_member_maeda from '../images/member/member_maeda.png';
import img_member_tozawa from '../images/member/member_tozawa.png';

import img_member_maruo from '../images/member/member_maruo.png';
import img_member_watanabe from '../images/member/member_watanabe.png';
import img_member_takeyama from '../images/member/member_takeyama.png';




const Member = () => {
  const breadcrumb = [{ label: 'メンバー紹介', url: '/member' }];
  
  const [isOpenVisible, setIsOpenVisible] = useState(false);
  const [isOpenNum, setIsOpenNum] = useState(0);
  
  function openModal(num) {
    setIsOpenNum(num);
    setIsOpenVisible(true);
  }
  function closeModal() {
    setIsOpenNum(0);
    setIsOpenVisible(false);
  }
  
  
  const memberlist = [
    {id: '1',name: '砂田 和也',position: '代表取締役CEO / 公認会計士',career: '大学在学中に公認会計士試験に合格。<br>有限責任あずさ監査法人に入所。<br>主に法定監査、IPO支援、 M&A等を担当。<br>2016年公認会計士登録。<br>2017年税理士登録、同年税理士法人を設立。ベンチャー企業の戦略的財務パートナーとしての税務会計ファームへ発展させ企業成長を支援。<br>数々の顧問先支援の中で、決算開示業務における数々のレガシー課題を再認識し、IR人材不足と課題を解決すべく、2020年8月に当社を設立、代表取締役に就任。',img: img_member_sunada,link: 'https://uniforce.co.jp/media/blog/f5llk01pquiv/' },
    {id: '3',name: '小川 卓也',position: '取締役COO',date: '2021年 入社',career: '2012年にシステム開発会社に新卒で入社し、50人規模の組織のマネージャーとして活躍。得意な開発言語はObjective-C、Swift、Android Java、Kotlinなどモバイルアプリケーション向けを中心に多岐に渡る。設計、開発、品質管理からプロジェクト・組織マネジメントまで幅広い経験の持ち主。<br>これまでで得た経験を直接プロダクトに反映させたいという想いから取締役CPOに就任。その後2023年11月から取締役COOに就任。',img: img_member_ogawa,link: 'https://uniforce.co.jp/media/blog/mjjxpbv27ye/' },
    {id: '4',name: '中島 敦',position: '執行役員CSO / 営業部 部長',date: '2023年 入社',career: '2007年に三菱東京UFJ銀行（現：三菱UFJ銀行）に入社。国内外で中小企業、大企業、スタートアップ向けのソリューション営業に12年超携わる。2022年には「事業競争投資」の第1号案件として担当先スタートアップ企業と合弁会社を設立し新規事業を創出。２０１６年から４年間は人材育成企画に従事し、新入行員の教育プログラムの企画・研修運営から海外拠点スタッフの育成まで幅広く担当。<br>16年半の銀行業務経験を活かし、より直接的に企業経営と新規事業創出に携わりたい想いから、2023年11月にUniforceの執行役員CSOに就任。',img: img_member_nakajima },
    {id: '5',name: '岩渕 一啓',position: '執行役員VPoP / プロダクト部 部長',date: '2024年 入社',career: '株式会社ヤプリでプロダクトマネージャーとしてのキャリアをスタート。 その後、スタートアップや上場企業のプロダクトマネージャーを歴任。Uniforceの描くビジョンに共感し、豊富なキャリアで得た実務経験とプロダクトマネジメントの深い知見をプロダクトに反映すべくUniforceに参画。',img: img_member_iwabuchi,link: 'https://uniforce.co.jp/media/blog/o9ieldkvj9a/' },
    {id: '6',name: '荒木 賢治',position: 'VPoF / 管理部 部長',date: '2024年 入社',career: '横浜国立大学卒業後、みずほ証券に入社。公開引受部に所属し、主に上場支援アドバイザリー業務、引受業務に従事。その後、上場企業カバレッジ業務を経て、2021年に株式会社日本M&Aセンター TOKYO PRO Market事業部に参画。TPM上場準備支援、コンサルティング業務に従事。2024年7月にUniforceに入社。',img: img_member_araki,link: 'https://uniforce.co.jp/media/blog/9hdlp60epin/' },
    {id: '7',name: '加藤 泰斗',position: 'コンサルティング事業部 担当部長 / 公認会計士',date: '2021年 入社',career: '2015年11月　公認会計士試験に合格（早稲田大学在学中）<br>2015年12月　太陽有限責任監査法人に入所。主に、金商法監査、会社法監査、IPO準備支援、リクルート業務を担当。法定監査では、幅広い業種（製造業/建設業/小売業//システム開発等）や規模（売上5億～2,000億円）のクライアントの監査を担当し、IPO準備支援では、内部統制支援アドバイザリー業務に従事。2021年2月 太陽有限責任監査法人を退社。2021年6月Uniforce株式会社に入社。BPO、IPO準備のコンサル、Uniforce開発支援に参加。',img: img_member_kato,link: 'https://uniforce.co.jp/media/blog/xfyioi9c15/' },
    {id: '8',name: '家村 修',position: '営業部 フロントセールス課 課長',date: '2023年 入社',career: '2016年法人向け通信キャリアに新卒入社。ネットワークサービス、セキュリティ、クラウド（SaaS,IaaS）のソリューション営業、マネージャーを経験。2020年には名古屋営業所の責任者として1年間在籍した後、本社で営業管理職として従事。<br>2023年Uniforce株式会社に入社。<br>IPO準備、経理業務、内部統制、資金調達など多岐にわたる支援をスタートアップから上場企業まで担当。',img: img_member_iemura },
    {id: '9',name: '前田 貴仁',position: '営業部 カスタマーサクセス課 / CS',date: '2023年 入社',career: 'アプリ社（現ダイブ社）で5年間、人材コーディネーターとして活躍し、二度の年間最優秀社員に選ばれる。次にNTT東日本でIT営業に転じ、多数の顧客のDX化を推進。その後、17LIVE社にて法人事業のカスタマーサクセスを立ち上げ、成長に貢献。2023年にUniforceへカスタマーサクセスの立ち上げから参画。現在はこれまでの顧客折衝経験を活かし、カスタマーサクセスの一人目担当として顧客満足度の向上に取り組んでいる。',img: img_member_maeda },
    {id: '10',name: '赤穂 陽菜乃',position: 'マーケティング部 / 広報',date: '2023年 入社',career: '2017年 株式会社SUPER STUDIOに入社し、ECサイトの立ち上げ等のクライアントワークに従事従事。その後、フリーランスとしてTikTokの縦型動画のクリエイティブ作成や企業のアカウント運用、キャスティングを行う。2023年9月にUniforce株式会社に入社しマーケティングと広報を担当。',img: img_member_akou,link: 'https://uniforce.co.jp/media/blog/cfv6hy8uv0lb/' },
    {id: '12',name: '根岸 裕太',position: '開発部 フロントエンド開発課 課長 / エンジニア',date: '2021年 入社',career: '2015年にシステム開発会社に新卒入社し、スマホアプリ開発を中心に多くのアプリケーションを開発。同社では技術系の役職者として開発組織の品質・技術力向上に貢献した。<br>Uniforce株式会社には1人目のエンジニアとして入社し、フロントエンド開発課の課長としてプロダクト開発に従事している。',img: img_member_negishi },
    {id: '13',name: '伊藤 明男',position: '開発部 バックエンド開発課 課長 / エンジニア',date: '2023年 入社',career: '新卒で株式会社ワークスアプリケーションズに入社し、業務システムのパフォーマンス検証などの業務に3年ほど従事。その後ゲーム業界に移り、長くゲームサービスのバックエンドの開発を担当する。株式会社ナウプロダクションでは総勢50名ほどのエンジニアチームのマネージャ(課長職)を務める。2023年11月にUniforce株式会社に入社し、SaaSプロダクトのバックエンド機能開発を担当。',img: img_member_ito },
    {id: '14',name: '風間 陽介',position: 'プロダクト部 プロダクト課 課長 / プロダクトマネージャー',date: '2022年 入社',career: '2005年にシステム開発に入社し、画像処理開発や翻訳アプリケーションのパッケージ開発からモバイルアプリケーションといった開発に従事。<br>その後はPL/PMとして上流工程を多岐にわたって経験。<br>これまでの経験から、よりユーザーに近い位置からシステムによる課題解決を届けるため2022年4月にUniforce株式会社に参画し、SaaSプロダクトのマネージャーを担当。',img: img_member_kazama },
    {id: '15',name: '冨田 竜之介',position: 'プロダクト部 / プロダクトマネージャー',date: '2024年 入社',career: '大学卒業後、専門商社の営業職としてキャリアをスタート。その後、大手BtoB ECサイト企業の新規事業責任者を経て、直近のBASE株式会社ではプロダクトマネージャーを務める。<br>現在はUniforce株式会社のプロダクトマネージャーとして、プロダクト設計とプロジェクトマネジメントを担当。',img: img_member_tomita },
    {id: '16',name: '戸澤 奈々',position: 'プロダクト部 / デザイナー',date: '2023年 入社',career: '新卒でデザイン会社に入社。デザイナーとして、UX/UIデザインなどのクライアントワークに従事。2023年11月にUniforce株式会社に1人目のデザイナーとして入社し、クリエイティブ制作を中心に幅広い業務を担当。',img: img_member_tozawa,link: 'https://uniforce.co.jp/media/blog/t9lb1np52e/' },
  ]

  const memberlist_adv = [
    {id: '17',name: '丸尾 浩一',position: '顧問',career: '1960年 大阪府生まれ。<br>大和証券株式会社に38年在籍し、専務取締役などの役員を12年間歴任。一貫して投資銀行業務に従事。<br>主幹事として株式会社メルカリやラクスル株式会社等、数々の大型スタートアップIPOを実現した他、経営破綻した日本航空株式会社の再上場や、楽天グループ株式会社、KDDI 株式会社等、上場企業の資金調達にも携わる。<br>現在、起業家支援サービスを提供する株式会社Major7thの代表取締役を務めながら、上場会社を含む複数社の社外役員等を兼務。',img: img_member_maruo },
    {id: '18',name: '渡邊 貴史',position: '顧問',career: '日系大手ITコンサルティングファームや日米のコンサルティングファーム、スタートアップ、ベンチャー企業での経営等に従事し、これまで20社以上のDeepTech系スタートアップの経営支援・資金調達支援を実行。R&Dやプロダクト企画・開発に係る支援を推進中。<br>その他、国や地方自治体の委員やアドバイザー等を受嘱。地方創生政策、地方自治体におけるスタートアップ支援政策なども行っている。',img: img_member_watanabe },
    {id: '19',name: '竹山 将志',position: 'ファイナンシャルアドバイザー',career: 'ソフトバンクグループに新卒入社。エンジニアマネージャーとしてシステム開発、事業開発マネージャーとして新規事業立上げを行う。<br>政府系ファンドを経て、ベンチャーキャピタル（VC）を複数立上げ。シード〜レイターステージのスタートアップへのインキュベーションやリード投資を行う。<br>VC立ち上げ準備期にはスタートアップのCFOを複数経験。学生・社会人・起業家向けアクセラレーションプログラムのメンターも複数務める。資金調達、事業開発、プロダクト開発の対応および支援実績が豊富。',img: img_member_takeyama },
  ]


  return (
    <>
      <Seo
        url="/member"
        title="メンバー紹介｜Uniforce（ユニフォース）株式会社"
        description=""
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <SubPageWrapper>
          <div className={Styles.member_page}>
            <Inner>
              <div className={Styles.title__wrapper}>
                <Title en="Our Member" jp="メンバー紹介" />
              </div>
              <div className={Styles.margin} />
              <div className={Styles.member_container}>
                <ul className={Styles.member_list}>
                {memberlist.map((item, i) => (
                  <li className={Styles.member_list__member_card}><button onClick={() => openModal(item.id)}>
                    <figure>
                      <div className={Styles.member_list__imgbox}><img src={item.img} alt="" width="238" /></div>
                      <figcaption><em>{item.position}</em><strong>{item.name}</strong></figcaption>
                    </figure>
                  </button></li>
                ))}
                </ul>
              </div>
              
              <div className={Styles.title__wrapper_adv}>
                <Title en="Our Adviser" jp="顧問・アドバイザー紹介" />
              </div>
              <div className={Styles.margin} />
              <div className={Styles.member_container}>
                <ul className={Styles.member_list}>
                {memberlist_adv.map((item, i) => (
                  <li className={Styles.member_list__member_card}><button onClick={() => openModal(item.id)}>
                    <figure>
                      <div className={Styles.member_list__imgbox}><img src={item.img} alt="" width="238" /></div>
                      <figcaption><em>{item.position}</em><strong>{item.name}</strong></figcaption>
                    </figure>
                  </button></li>
                ))}
                </ul>
              </div>

              <div className={Styles.margin} />
              <div className={Styles.button__wrapper}>
                <div className={Styles.member_page__btn}>
                  <BackButton path="/" />
                </div>
              </div>
            </Inner>
          </div>
        </SubPageWrapper>
      {isOpenVisible && (
        <Modal
          isOpen={isOpenVisible}
          onRequestClose={closeModal}
          className={Styles.modal_wrapper}
          style={{
            overlay: {
              height: '100%',
              width: '100%',
              zIndex: '1000',
              background: 'rgba(62,65,83,0.2)'
            },
          }}
        >
          {memberlist.map((item, i) => (
            <>
              {isOpenNum === item.id && 
                <div className={Styles.modal_container}>
                  <div className={Styles.modal_container__imgbox}><img src={item.img} alt="{item.name}" width="238" /></div>
                  <div className={Styles.modal_container__textbox}>
                  {item.date && <p><em>{item.position}</em><br /><strong>{item.name}</strong><br /><small>{item.date}</small></p> }
                  {!item.date && <p><em>{item.position}</em><br /><strong>{item.name}</strong></p> }
                    <dl>
                      <dt>経歴</dt>
                      <dd dangerouslySetInnerHTML={{
                        __html: `${item.career.replace(/\n/g, '<br />')}`,
                      }}></dd>
                    </dl>
                  </div>
                  {item.link &&  <div className={Styles.button_container}><a className={Styles.button_container__button} href={item.link} target="_blank" rel="noreferrer">interview</a></div> }
                </div>
              }
            </>
          ))}
          {memberlist_adv.map((item, i) => (
            <>
              {isOpenNum === item.id && 
                <div className={Styles.modal_container}>
                  <div className={Styles.modal_container__imgbox}><img src={item.img} alt="{item.name}" width="238" /></div>
                  <div className={Styles.modal_container__textbox}>
                  {item.date && <p><em>{item.position}</em><br /><strong>{item.name}</strong><br /><small>{item.date}</small></p> }
                  {!item.date && <p><em>{item.position}</em><br /><strong>{item.name}</strong></p> }
                    <dl>
                      <dt>経歴</dt>
                      <dd dangerouslySetInnerHTML={{
                        __html: `${item.career.replace(/\n/g, '<br />')}`,
                      }}></dd>
                    </dl>
                  </div>
                  {item.link &&  <div className={Styles.button_container}><a className={Styles.button_container__button} href={item.link} target="_blank" rel="noreferrer">interview</a></div> }
                </div>
              }
            </>
          ))}
          <button className={Styles.modal_wrapper__close} onClick={() => closeModal()}>閉じる</button>
        </Modal>
      )}
      </Layout>
    </>
  );
}

export default Member;